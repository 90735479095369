//
//    Desktop
//--------------------------------------
#search_autocomplete, .search-autocomplete, #searchbox_autocomplete {
    display: none !important;
    height: 0 !important;
    overflow: hidden !important;;
}

// compatibility with porto theme
.block-search {
    z-index: 10000;
}