& when (@media-common = true) {
    .provider (@name, @background, @color, @icon, @iconBackground) {
        .mstSocialLogin__button {
            &._@{name} {
                @border-radius: .2rem;

                background: darken(@background, 5);
                color: @color;
                display: flex;
                flex-wrap: nowrap;
                align-items: center;
                border-radius: @border-radius;
                padding: 0 1.5rem 0 0;
                min-height: 3.4rem;


                &:before {
                    content: '';
                    background: @iconBackground url(@icon) no-repeat center;
                    display: block;
                    width: 3.4rem;
                    height: 3.4rem;
                    padding: .5rem;
                    box-sizing: border-box;
                    background-size: 2.5rem;
                    margin-right: 1rem;
                }

                &._mode-icon {
                    padding: 0;
                    border-radius: 50%;
                    box-shadow: 0 .1rem .6rem 0 rgba(0, 0, 0, 0.3);
                    overflow: hidden;
                    background: @iconBackground;

                    &:before {
                        margin-right: 0;
                        border: none;
                    }

                    > span {
                        display: none;
                    }
                }
            }
        }

        .mstSocialLogin__color {
            &._@{name} {
                background: @background;
                color: @color;
            }
        }

        .mstSocialLogin__border {
            &._@{name} {
                border-color: @background;
            }
        }

        .mstSocialLogin__icon {
            &._@{name} {
                &:before {
                    content: '';
                    background: darken(@background, 5) url(@icon) no-repeat center;
                    display: block;
                    width: 3.4rem;
                    height: 3.4rem;
                    padding: .5rem;
                    box-sizing: border-box;
                    background-size: 2.5rem;
                    margin-right: 1rem;
                }
            }
        }
    }

    .makeProviders(@socialLoginProviders);

    .makeProviders(@list) {
        .iter(length(@list));
        .iter(@i) when (@i > 0) {
            .iter(@i - 1);
            @name:  extract(@list, @i);

            @bg: "socialLoginBg-@{name}";
            @fg: "socialLoginFg-@{name}";
            @icon: "socialLoginIcon-@{name}";
            @iconBg: "socialLoginIconBg-@{name}";

            .provider(@name, @@bg, @@fg, @@icon, @@iconBg);
        }
    }
}
