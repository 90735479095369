// /**
//  * Copyright © Magento, Inc. All rights reserved.
//  * See COPYING.txt for license details.
//  */

//
//  Responsive
//  _____________________________________________

//
//  Media variables, that can be used for splitting styles into several files
//  ---------------------------------------------

@media-common: true; // Sets whether to output common styles (true|false)
@media-target: 'all'; // Sets target device for styles output (all|desktop|mobile)

//
//  Media width mixin used to group styles output based on media queries
//  ---------------------------------------------

.media-width(@extremum, @break) when (@extremum = 'max') and (@break = @screen__s) {
}

//
//  Style groups for 'mobile' devices
//  ---------------------------------------------

& when (@media-target = 'mobile'), (@media-target = 'all') {

    @media only screen and (max-width: @screen__m) {
        .media-width('max', (@screen__m + 1));
    }

    @media only screen and (max-width: (@screen__m - 1)) {
        .media-width('max', @screen__m);
    }

    @media only screen and (max-width: (@screen__s - 1)) {
        .media-width('max', @screen__s);
    }

    @media only screen and (max-width: (@screen__xs - 1)) {
        .media-width('max', @screen__xs);
    }

    @media only screen and (max-width: (@screen__xxs - 1)) {
        .media-width('max', @screen__xxs);
    }

    @media all and (min-width: @screen__s) {
        .media-width('min', @screen__s);
    }

}

//
//  Style groups for 'desktop' devices
//  ---------------------------------------------

& when (@media-target = 'desktop'), (@media-target = 'all') {

    @media all and (min-width: @screen__m),
    print {
        .media-width('min', @screen__m);
    }

    @media all and (min-width: (@screen__m + 1)),
    print {
        .media-width('min', (@screen__m + 1));
    }

    @media all and (min-width: @screen__l),
    print {
        .media-width('min', @screen__l);
    }

    @media all and (min-width: @screen__xl),
    print {
        .media-width('min', @screen__xl);
    }
}
