//
//  Mixins
//  _________________________

.am-gdprcookie-scrollbar(
    @color: @amgdprcookie-scroll__background,
    @second-color: #fff,
    @radius: 0,
    @width: 4px
) {
    & {
        overflow: auto;

        // Firefox Custom Scrollbar Styles
        scrollbar-color: @color @second-color;
        scrollbar-width: thin;
        // (END) Firefox Custom Scrollbar Styles

        // IE Custom Scrollbar Styles
        scrollbar-face-color: @color;
        scrollbar-highlight-color: @color;
        scrollbar-shadow-color: @color;
        scrollbar-track-color: @second-color;
        // (END) IE Custom Scrollbar Styles
    }

    // Webkit Custom Scrollbar Styles
    &::-webkit-scrollbar {
        width: @width;
    }

    &::-webkit-scrollbar-track {
        background-color: @second-color;
        box-shadow: none;
    }

    &::-webkit-scrollbar-thumb {
        border-radius: @radius;
        background: @color;
    }
    // (END) Webkit Custom Scrollbar Styles
}
