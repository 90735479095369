//
//  One Step Checkout Modern Design
//  _____________________________________________

//
//  Variables
//  ---------------------------------------------

@amcheckout-field__height: 40px;
@amcheckout-checkbox-radio-icon__margin: 1px 15px 0 0;
@amcheckout-checkbox__checked__background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjAiIGhlaWdodD0iMjAiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHJlY3Qgd2lkdGg9IjIwIiBoZWlnaHQ9IjIwIiByeD0iMiIgZmlsbD0iIzE5NzlDMyIvPjxwYXRoIGQ9Ik01IDEwbDQgNCA2LTciIHN0cm9rZT0iI2ZmZiIgc3Ryb2tlLXdpZHRoPSIyIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiLz48L3N2Zz4=) center no-repeat;
@amcheckout-radio-icon__size: 15px;
@amcheckout-delete-product-icon__background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTAiIGhlaWdodD0iMTUiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0xLjE2NyAxNWg3LjU1OGwuOTUtOC45NThILjI2TDEuMTY3IDE1em01Ljg5LTEyLjc3MlYuNTEyQS41MS41MSAwIDAgMCA2LjU1MiAwaC0zLjIxYy0uMjggMC0uNTA1LjIzLS41MDUuNTEyVjIuMmMtMS4wODUuMTIzLTEuNzExLjMxNS0yLjgzNi43MjRWNC41bDEwIC4xMThWM3MtLjk1NC0uNTM0LTIuOTQyLS43NzJ6TTYuMDUgMi4xMzRhMTguMDggMTguMDggMCAwIDAtMi4yNTgtLjAxNXYtLjkwNWMwLS4xNC4xMTEtLjI1NS4yNTItLjI1NWgxLjc1MmMuMTQxIDAgLjI1NC4xMTUuMjU0LjI1NXYuOTJ6IiBmaWxsPSIjNTc1ODU1Ii8+PC9zdmc+) center no-repeat;
@amcheckout-delete-product-icon__hover__background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTAiIGhlaWdodD0iMTUiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0xLjE2NyAxNWg3LjU1OGwuOTUtOC45NThILjI2TDEuMTY3IDE1em01Ljg5LTEyLjc3MlYuNTEyQS41MS41MSAwIDAgMCA2LjU1MiAwaC0zLjIxYy0uMjggMC0uNTA1LjIzLS41MDUuNTEyVjIuMmMtMS4wODUuMTIzLTEuNzExLjMxNS0yLjgzNi43MjRWNC41bDEwIC4xMThWM3MtLjk1NC0uNTM0LTIuOTQyLS43NzJ6TTYuMDUgMi4xMzRhMTguMDggMTguMDggMCAwIDAtMi4yNTgtLjAxNXYtLjkwNWMwLS4xNC4xMTEtLjI1NS4yNTItLjI1NWgxLjc1MmMuMTQxIDAgLjI1NC4xMTUuMjU0LjI1NXYuOTJ6IiBmaWxsPSIjMTk3OWMzIi8+PC9zdmc+) center no-repeat;
@amcheckout-modern__border-color: #d4d4d4;
@amcheckout-modern-main__color: #B5B5B5;
@amcheckout-modern-btn__color: #315499;
@amcheckout-modern-2column-sidebar__width: 380px;
@amcheckout-modern-2column-gap__width: 60px;
@amcheckout-modern-edit-icon__background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTUiIGhlaWdodD0iMTUiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0xMi42MTMgMGwtMS43MDYgMS43MTYgMi4zODggMi40TDE1IDIuNDAzIDEyLjYxMyAwek0xLjcgMTAuOThsMi4zODYgMi40IDguMTg3LTguMjMzLTIuMzg3LTIuNDAxTDEuNyAxMC45OHpNMCAxNWwyLjg5LS43NjMtMi4xMy0yLjE0NEwwIDE1eiIgZmlsbD0iIzRFNEU0RSIvPjwvc3ZnPg==) center no-repeat;
@amcheckout-modern-edit-icon__hover__background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTUiIGhlaWdodD0iMTUiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0xMi42MTMgMGwtMS43MDYgMS43MTYgMi4zODggMi40TDE1IDIuNDAzIDEyLjYxMyAwek0xLjcgMTAuOThsMi4zODYgMi40IDguMTg3LTguMjMzLTIuMzg3LTIuNDAxTDEuNyAxMC45OHpNMCAxNWwyLjg5LS43NjMtMi4xMy0yLjE0NEwwIDE1eiIgZmlsbD0iIzE5NzlDMyIvPjwvc3ZnPg==) center no-repeat;
@ambase__btn-update-color: #1787e0;

@gc-dark-blue-color: #122948;
@gc-blue-color: #00ADE7;
@gc-ultra-light-grey-color: #F4F4F4;
@gc-light-grey-color: #F2F2F2;
@gc-grey-color: #B5B5B5;
@gc-orange-color: #E6A532;
@gc-dark-orange-color: #CB9012;
@gc-button-active-bg-color: rgba(0,173,231,0.06);
@gc-button-active-hover-bg-color: rgba(0, 173, 231, 0.35);
@default-transition: all 250ms ease-in-out;
@box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.15);

//
//  Common
//  ---------------------------------------------

& when (@media-common = true) {
    .am-checkout.-modern {
        & {
            box-sizing: border-box;
            padding: 0;
            background: none;
            max-width: var(--container-width);
            margin: 0 auto;
            padding: 0 20px;
        }

        &.-layout-1column {
            margin: auto;
            max-width: 750px;
        }

        .checkout-header {
            padding: 0;
        }

        .authentication-wrapper {
            margin-right: 0;
        }

        .authentication-wrapper .action-auth-toggle {
            padding: 10px 13px;
            height: auto; // Porto button height fix
            background: #00ADE7;
            color: #fff;
            overflow: hidden;
            border-radius: var(--border-radius);
        }

        .opc-estimated-wrapper {
            margin: 0 -15px 15px;
        }

        .block .title strong {
            font-weight: bold;
            font-size: 14px;
            line-height: 19px;
        }
    }

    .am-checkout.-modern.-layout-2columns,
    .am-checkout.-modern.-layout-3columns {
        .product-item-details .product-item-inner {
            .amcheckout-price-wrapper {
                order: inherit;
                text-align: start;
            }
        }
    }

    .amcheckout-main-container.-modern {
        & {
            clear: both;
            color: #363636;
        }
    }

    // General styles
    .amcheckout-step-container {
        & {
            font-size: 14px;
        }

        & > li {
            list-style: none;
        }

        .amcheckout-title {
            display: block;
            margin: 0;
            font-weight: bold;
            font-size: 20px;
            line-height: 27px;
        }

        .amcheckout-shipping-address {
            .amcheckout-title {
                padding-bottom: 10px;
            }
        }

        .amcheckout-title > .amcheckout-icon {
            & {
                position: relative;
                float: right;
                box-sizing: border-box;
                width: 24px;
                height: 24px;
                border: 2px solid @amcheckout-modern-main__color;
                border-radius: 50%;
                transition: all .3s ease;
            }

            &:hover,
            &:hover:before {
                border-color: lighten(@amcheckout-modern-main__color, 15%);
            }
        }

        .amcheckout-title > .amcheckout-icon:before {
            & {
                position: absolute;
                top: 41%;
                left: 50%;
                display: block;
                box-sizing: border-box;
                width: 8px;
                height: 8px;
                border-right: 2px solid @amcheckout-modern-main__color;
                border-bottom: 2px solid @amcheckout-modern-main__color;
                content: '';
                transition: all .3s ease;
                transform: translate(-50%, -50%) rotate(45deg);
            }
        }

        .amcheckout-title > .amcheckout-icon.-minus {
            transform: rotate(180deg);
        }

        .amcheckout-title + .amcheckout-content > .amcheckout-wrapper {
            padding: 20px 0;
        }

        .fieldset.address {
            display: flex;
            flex-wrap: wrap;

            .field {
                order: 2;
            }

            div[name$=".company"],
            div[name$=".vat_id"]{
                order: 1;
                padding: 15px;
                background: #F2F2F2;
                margin: 0;
            }
            div[name$=".company"] {
                padding-bottom: 0;
                overflow: hidden;
                border-top-left-radius: var(--border-radius);
                border-top-right-radius: var(--border-radius);
            }
            div[name$=".vat_id"] {
                margin-bottom: 12px;
                overflow: hidden;
                border-bottom-left-radius: var(--border-radius);
                border-bottom-right-radius: var(--border-radius);
            }
        }

        .fieldset:last-child {
            margin-bottom: 0;
        }

        .fieldset > .legend + br {
            display: none;
        }

        .fieldset > .field > .label,
        .fieldset > .fields > .field > .label {
            font-weight: bold;
            font-size: 14px;
            line-height: 19px;
        }

        .opc-block-summary {
            margin: 0;
            padding: 0;
        }

        .checkout-payment-method .field-select-billing,
        .checkout-payment-method .billing-address-form {
            max-width: inherit;
        }

        .checkout-payment-method .checkout-billing-address {
            margin-top: 15px;
        }

        .note {
            color: #777;
        }

        .trigger-block {
            display: flex;
            gap: 10px;
            padding-bottom: 20px;
        }

        .fieldset.company-block {
            background: #F2F2F2;
            padding: 15px 20px 20px;
        }

        .amcheckout-button,
        button {
            &:not(.braintree-googlepay-button, .ui-datepicker-trigger,.gpay-card-info-container) {
                background: @gc-ultra-light-grey-color;
                border: 2px solid @gc-ultra-light-grey-color;
                box-shadow: none;
                padding: 10px 20px;
                font-weight: black;
                font-weight: regular;
                transition: box-shadow 250ms ease-in-out;
                overflow: hidden;
                border-radius: var(--border-radius);

                &.active {
                    background: @gc-button-active-bg-color;
                    border: 2px solid @gc-blue-color;
                    color: @gc-dark-blue-color;
                    pointer-events: none;
                    padding: 8px 18px;
                    box-shadow: none;
                }

                &:not(.active):hover {
                background: @gc-light-grey-color;
                border-color: @gc-light-grey-color;
                box-shadow: @box-shadow;
                }
            }

            &:not(.braintree-googlepay-button):hover {
                // magento button hover border override
            }
        }

        .amcheckout-button.-new-address {
            margin-top: 20px;
        }

        .payment-method-content > .actions-toolbar > .primary .action.primary {
            float: none;
            margin: 0 15px 0 0;
            padding: 12px 16px;
            font-size: 14px;
        }

        .action-edit-address {
            margin-top: 15px;
        }

        .primary .action.checkout,
        .action.checkout.amasty {
            padding: 14px 30px;
            font-weight: bold;
            background: var(--color-orange);
            border: none;
            width: 100%;
            font-size: 18px;

            &:hover {
                background: var(--color-dark_orange);
                box-shadow: none;
            }
        }

        // There is fix for discount apply button height
        .form-discount .actions-toolbar .action {
            min-height: 40px;
        }

        input[type='number']:focus {
            -moz-appearance: none !important;
        }

        input[type='text'],
        input[type='password'],
        input[type='url'],
        input[type='tel'],
        input[type='search'],
        input[type='number'],
        input[type='datetime'],
        input[type='email'],
        select {
            height: @amcheckout-field__height;
            border: 1px solid @amcheckout-modern__border-color;
            border-radius: 2px;
            font-size: 14px;
            overflow: hidden;
            border-radius: var(--border-radius);
        }

        textarea {
            overflow: hidden;
            border-radius: var(--border-radius);
        }

        .checkout-agreements .action-show {
            background: inherit;
            color: inherit;
            font-size: inherit;
        }

        // Porto button height fix + options for magento button-link
        .checkout-agreements .action-show,
        .action.checkout.amasty {
            height: auto;
        }

        .field.field-select-billing {
            margin: 0 0 10px 0;

            & > .label {
                font-size: 20px;
                line-height: 27px;
            }
        }
    }

    .checkout-billing-address {
        .fieldset .field.field-select-billing .label {
            padding-bottom: 10px;
        }
    }

    body.hide-company-fields {
        .amcheckout-step-container{
            .fieldset.address {
                div[name$=".company"],
                div[name$=".vat_id"] {
                    display: none;
                }
            }
        }
    }

    //Checkbox + radio buttons restyling
    .amcheckout-step-container {

        input[type='checkbox'],
        input[type='radio'] {
            .lib-visually-hidden();

            top: unset;
            left: unset;
        }

        input[type='checkbox'] + label,
        input[type='radio'] + label {
            display: flex;
            flex-direction: row-reverse;
            justify-content: space-between;
            cursor: pointer;
        }

        input[type='checkbox'] + label span:only-child,
        input[type='radio'] + label span:only-child {
            flex: auto;
        }

        input[type='checkbox'] + label:before,
        input[type='radio'] + label:before {
            display: inline-block;
            box-sizing: border-box;
            margin: @amcheckout-checkbox-radio-icon__margin;
            min-width: @amcheckout-radio-icon__size;
            min-height: @amcheckout-radio-icon__size;
            width: @amcheckout-radio-icon__size;
            height: @amcheckout-radio-icon__size;
            border: 1px solid #ccc;
            background: #fff !important;
            content: '';
            cursor: pointer;
        }

        input[type='checkbox'] + label:hover:before,
        input[type='radio'] + label:hover:before,
        input[type='checkbox']:checked + label:before,
        input[type='radio']:checked + label:before {
            border-color: @amcheckout-modern-main__color;
        }

        input[type='checkbox']:checked + label:before {
            border-width: 0;
            background: @amcheckout-checkbox__checked__background !important;
            background-size: contain !important;
        }

        input[type='radio'] + label:before {
            border-radius: 50%;
            transition: all .2s ease;
        }

        input[type='radio']:checked + label:before {
            border-width: 5px;
        }
    }

    // Login form
    .amcheckout-step-container .amcheckout-form-login,
    .amcheckout-step-container .form-login {
        .amcheckout-email {
            display: flex;
            align-items: flex-start;
            flex-wrap: wrap;
        }

        .amcheckout-email .amcheckout-wrapper {
            -webkit-box-flex: 1;
            -ms-flex: auto;
            flex: auto;
        }

        .amcheckout-password {
            width: ~'calc(50% - @{amcheckout-fieldbox-side__padding})';
        }

        .amcheckout-email .amcheckout-wrapper .input-text {
            margin: 0;
            width: 100%;
        }

        .amcheckout-email .field-tooltip {
            position: relative;
            top: 0;
            display: flex;
            align-items: center;
            margin-left: 10px;
            height: @amcheckout-field__height;
        }

        .amcheckout-email .note {
            color: #363636;
            font-size: 14px;
            line-height: 19px;

            &:before {
                display: none;
            }
        }

        .amcheckout-password {
            display: inline-block;
        }

        .amcheckout-password.-confirm {
            float: right;
        }

        #customer-email-fieldset {
            .actions-toolbar {
                margin-left: 0;
                > .primary {
                    width: 100%;
                    > .action.login {
                        background: #00ADE7;
                        color: #fff;
                        border: none;
                        &:hover {
                            background: #007be7;
                        }
                    }
                }
            }
        }
        .actions-toolbar {
            margin-bottom: 20px;
        }
    }

    // Shipping address section
    .amcheckout-step-container .amcheckout-shipping-address {
        .checkout-billing-address {
            margin-top: 20px;
        }

        .billing-address-same-as-shipping-block,
        .billing-address-form,
        .shipping-address-form {
            margin-bottom: 10px;
        }

        .billing-address-details,
        .shipping-address-details {
            padding: 20px;

            &:extend(._amcheckout-selectable-item all);
        }

        .billing-address-details:empty,
        .shipping-address-details:empty {
            display: none;
        }

        .amcheckout-button {
            margin-top: 20px;
        }

        .amcheckout-button.-select {
            & {
                float: right;
                border: 1px solid @ambase__btn-update-color;
                background: #ddf0ff;
                color: @amcheckout-modern-main__color;
            }

            &:hover {
                background: @ambase__btn-update-color;
                color: #fff;
            }
        }

        .amcheckout-button.-edit,
        .amcheckout-button.-new-address {
            & {
                background: @gc-blue-color;
                color: white;
                font-weight: bold;
                border: none;
                transition: @default-transition;
            }

            &:hover {
                background-color: @gc-dark-blue-color;
                border: none;
                box-shadow: none;
            }

        }
    }

    .amcheckout-step-container .shipping-address-items {
        &:extend(._amcheckout-selectable-container);

        .shipping-address-item {
            padding: 20px;
            line-height: 1.5;

            &:extend(._amcheckout-selectable-item all);
        }

        .shipping-address-item.selected-item {
            &:extend(._amcheckout-selectable-item.-selected);
        }

        .shipping-address-item.selected-item .amcheckout-button.-select {
            visibility: hidden;
        }
    }

    // Shipping methods section
    .amcheckout-step-container .amcheckout-shipping-methods {
        & {
            display: block;
            max-width: inherit !important;
        }

        .amcheckout-header {
            display: none;
        }

        .amcheckout-items {
            &:extend(._amcheckout-selectable-container);
        }

        .amcheckout-method {
            padding: 0 5px;

            &:extend(._amcheckout-selectable-item all);
        }

        .amcheckout-method.-selected {
            &:extend(._amcheckout-selectable-item.-selected);
        }

        .amcheckout-method .col:not(:first-child) {
            border: none;
            min-width: 100px;
            width: 45%;

            &.col-carrier,
            &.col-price {
                font-weight: bold;
            }

            &.col-price .zero {
                color: #E6A532;
            }
        }

        .amcheckout-method .col-comment .field-tooltip {
            position: relative;
        }

        .amcheckout-method .col-comment .field-tooltip.-error {
            .field-tooltip-action:before,
            .field-tooltip-content {
                color: #c00815;
            }
        }
    }

    // Billing address block
    .amcheckout-step-container .checkout-billing-address {
        .actions-toolbar {
            margin-bottom: 15px;
        }

        .actions-toolbar > .primary {
            float: none;
            display: flex;
            gap: 10px;
            justify-content: center;
        }

        .actions-toolbar > .primary .action {
            margin: 0;
            font-weight: bold;
            background-color: @gc-blue-color;
            color: white;
            border: none;

            &:hover {
                border: none;
            }

            &-update {
                transition: @default-transition;

                &:hover {
                    box-shadow: none;
                    background-color: @gc-dark-blue-color;
                }
            }

            &-cancel {
                color: black;
                background-color: @gc-ultra-light-grey-color;
                font-weight: bold;

                &:hover {
                    background-color: @gc-ultra-light-grey-color;
                    
                }
            }
        }

        .amcheckout-button.-edit {
            &:extend(.amcheckout-step-container .amcheckout-shipping-address .amcheckout-button.-edit all);
        }
    }

    // Delivery date section
    .amcheckout-step-container .amcheckout-delivery-date {
        .field {
            width: 100%;
        }

        .field.timepicker {
            display: block;
        }

        .field.timepicker .control {
            width: 100%;
        }
    }
    .amcheckout-step-container .checkout-payment-method {

        .form.payments {
            .legend {
                display: none;
            }
        }
    }


    // Payment methods section
    .amcheckout-step-container .checkout-payment-method .amcheckout-payment-methods {
        & {
            margin: 0;
        }

        .amcheckout-content > .amcheckout-wrapper {
            &:extend(._amcheckout-selectable-container);
        }

        .payment-group + .payment-group .step-title { //magento selector
            margin: 0;
        }

        .payment-group:not(:last-of-type) {
            margin-bottom: 40px;
        }

        .payment-method {
            width: 100%;

            &:extend(._amcheckout-selectable-item all);
        }

        .payment-method:not(:last-of-type) {
            margin-bottom: 20px;
        }

        .payment-method._active {
            &:extend(._amcheckout-selectable-item.-selected);
        }

        .payment-method-title {
            padding: 0;
            border: none !important;
        }

        .payment-method-title .label {
            padding: 15px 15px 0;
            margin-bottom: 15px;
            align-items: center;
            font-weight: bold;

            img {
                max-width: 30px;
            }

            &:before {
                display: none;
            }
        }

        .payment-method-title .label > *:not(:only-child):not(:last-of-type) {
            margin-right: 5px;
        }

        // paypal help actions alignment fix
        .payment-method-title .label .action-help {
            margin-left: auto;
            text-align: right;
        }

        .payment-method .payment-method-content {
            display: none;
        }

        .payment-method._active .payment-method-content {
            display: block;
            margin-bottom: 15px;
            padding: 0 15px;
        }

        .payment-method._active .payment-method-content > * {
            margin: 0;
        }

        .payment-method._active .payment-method-content > * > * {
            margin: 0 0 20px;
        }

        //amazon pay payment method table-cell fix
        .payment-method._active .payment-method-content .amazon-button-container__cell {
            display: inline-block;
            vertical-align: middle;
        }

        .payment-method-content .actions-toolbar > .primary {
            float: left;
            padding-left: 0;
        }
    }

    // Order summary section
    .amcheckout-step-container .amcheckout-summary-container {
        & {
            background: none;
            overflow: hidden;
            border-radius: var(--border-radius);
        }

        .amcheckout-title {
            background: #fff;
        }

        .amcheckout-title > .amcheckout-icon {
            background: #363636;
        }

        .amcheckout-content > .amcheckout-wrapper {
            padding: 20px;
        }

        .items-in-cart {
            margin-bottom: 20px;
        }

        .product-item .delete {
            & {
                position: absolute;
                right: 0;
                bottom: 0;
                z-index: 1;
                box-sizing: border-box;
                padding: 0;
                width: 10px;
                height: 15px;
                border: 0;
                background: @amcheckout-delete-product-icon__background;
                background-size: contain;
            }

            &:hover {
                background: @amcheckout-delete-product-icon__hover__background;
                background-size: contain;
            }
        }

        .product-item .action.checkout {
            float: left;
            margin-bottom: 10px;
        }

        .minicart-items .product-image-container {
            margin: 0;
            border: 1px solid @amcheckout-modern__border-color;
        }

        .minicart-items .product-item-details {
            padding-left: 20px;
        }

        .minicart-items .minicart-items-wrapper {
            padding: 15px 0 0;
        }

        .product-item-details .product-item-inner {
            align-items: center;
            display: flex;
            flex-wrap: wrap;

            .amcheckout-price-wrapper {
                flex-basis: 100%;
                order: 1;
                text-align: end;
            }
        }

        .product-item-details .product-item-name-block {
            display: flex;
            align-items: start;
            -webkit-box-flex: 1;
            -ms-flex: auto;
            flex: auto;
            justify-content: space-between;

            .qty-value {
                display: inline-block;
                padding-right: 5px;
                font-weight: bold;
                &:after {
                    content: '×';
                }
            }
        }

        .product-item-details .action.checkout {
            & {
                border: 1px solid @ambase__btn-update-color;
                border-radius: 2px;
                background: #f0f8ff;
                color: @ambase__btn-update-color;
            }

            &:hover {
                background: darken(#f0f8ff, 2%);
            }
        }

        .product-item-details .options {
            padding-right: 20px;
        }

        .product-item-name {
            margin: 0;
        }

        .details-qty {
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            margin: 10px 0 0;
        }

        .details-qty .label {
            margin-right: 10px;
        }

        .details-qty .qty {
            height: 30px;
        }

        .subtotal {
            width: auto;
        }

        .field.configurable {
            width: 50%;
        }

        .field.configurable:not(:last-of-type) {
            margin-bottom: 10px;
        }

        .table-totals {
            width: 100%;
            border: none;
            font-size: 14px;
        }

        .table-totals .grand .mark,
        .table-totals .grand .amount {
            border-top: none;
        }

        .item-options .label {
            display: block;
            margin-bottom: 10px;
        }
    }

    // Additional options section
    .amcheckout-step-container .amcheckout-additional-options {
        & {
            margin: 0;
            font-size: 0;
        }

        > * {
            font-size: 14px;
        }

        .amcheckout-checkboxes > * {
            margin-bottom: 5px;
        }

        .checkout-agreement .action-show {
            text-align: left;
        }
    }

    // Payment additions elements (discount, rewards)
    .amcheckout-step-container .opc-payment-additional {
        & {
            padding: 0 20px;
            background: #F2F2F2;
            margin-bottom: 20px;
            overflow: hidden;
            border-radius: var(--border-radius);
        }

        .action-toggle {
            display: flex;
            justify-content: space-between;
        }
        .primary {
            width: 100%;
        }
        .action-apply {
            background: @gc-blue-color;
            color: #fff;
            border: none;
            transition: @default-transition;
            font-weight: bold;
            overflow: hidden;
            border-radius: var(--border-radius);
            &:hover {
                background: @gc-dark-blue-color !important;
                color: #fff !important;
                border: none !important;
                box-shadow: none !important;
            }
        }

        .payment-option-title,
        .payment-option-content {
            padding-right: 0;
            padding-left: 0;
        }

        .label {
            display: block;
            margin-bottom: 4px;
        }

        .form-discount .label {
            display: none;
        }
    }

    // Modern 2 columns design
    .amcheckout-main-container.-layout-2columns {
        .amcheckout-column:not(:first-of-type) {
            margin-top: 40px;
        }
    }
}

//
//  Tablet +
//  ---------------------------------------------

.media-width(@extremum, @break) when (@extremum = 'min') and (@break = @screen__l) {
    #checkout.am-checkout.-modern {
        &.-layout-1column .authentication-wrapper {
            min-width: 300px;
        }
    }

    .amcheckout-main-container.-modern .amcheckout-step-container {
        .amcheckout-form-login .amcheckout-email .amcheckout-wrapper {
            -webkit-box-flex: inherit;
            -ms-flex: inherit;
            flex: inherit;
            width: ~'calc(50% - @{amcheckout-fieldbox-side__padding})';
        }

        .amcheckout-delivery-date {
            .field.date,
            .field.timepicker {
                width: 50%;
            }

            .field.date {
                padding-right: 10px;
            }

            .field.timepicker {
                padding-left: 10px;
            }
        }

        .amcheckout-summary-container {
            .amcheckout-title {
                color: #363636;
            }

            .amcheckout-title > .amcheckout-icon {
                background: none;
            }

            .subtotal {
                width: 15%;
            }

            .table-totals {
                margin-left: auto;
                width: 40%;
            }
        }

        .amcheckout-additional-options {
            .amcheckout-checkboxes,
            .amcheckout-comment {
                display: inline-block;
                vertical-align: top;
            }

            .amcheckout-checkboxes {
                box-sizing: border-box;
                padding-right: 10px;
                width: 100%;
            }

            .amcheckout-comment {
                width: 100%;
            }
        }

        .checkout-payment-method .payment-option-title {
            border: none;
        }
    }

    // Modern 1 column design
    .checkout-index-index:not(.amasty-jet-theme) .amcheckout-main-container.-modern.-layout-1column .amcheckout-summary-container .details-qty {
        max-width: 20%;
    }

    // Modern 2 columns design
    .amcheckout-main-container.-modern.-layout-2columns {
        & {
            position: relative;
        }

        .amcheckout-column {
            margin: 0;
        }

        .amcheckout-column.-main {
            float: left;
            width: ~'calc(100% - (@{amcheckout-modern-2column-sidebar__width} + @{amcheckout-modern-2column-gap__width}))';
        }

        .amcheckout-column.-sidebar {
            float: right;
            width: @amcheckout-modern-2column-sidebar__width;
            transition: all .3s ease;
        }

        .amcheckout-column.-sidebar.-sticky {
            overflow: auto;
            max-height: 100vh;
        }
    }

    .amcheckout-main-container.-modern.-layout-2columns .amcheckout-step-container {
        &.-summary {
            background: #fff;
        }

        &.-summary > .checkout-payment-method .actions-toolbar {
            margin-bottom: 20px;
            text-align: center;
            background: #fff;
        }

        &.-summary > .checkout-payment-method .actions-toolbar .amasty.checkout {
            float: none;
            width: 100%;
        }

        .amcheckout-summary-container {
            &,
            .amcheckout-title {
                background: #fff;
                padding-bottom: 20px;
                cursor: default;
            }

            .amcheckout-content {
                border: 1px solid #d4d4d4;
                overflow: hidden;
                border-radius: var(--border-radius);
            }

            .product-item-name {
                width: 100%;
            }

            .subtotal {
                width: auto;
            }

            .table-totals {
                margin-left: 0;
                width: 100%;
            }

            .backtocart-link {
                padding: 20px 4px 0;
                a {
                    cursor: pointer;
                    color: #00ADE7;
                    text-decoration: underline;
                    &:hover {
                        text-decoration: none;
                    }
                }
            }
        }

        .amcheckout-additional-options {
            & {
                background: #fff;
            }

            .amcheckout-checkboxes,
            .amcheckout-comment {
                display: block;
                width: 100%;
            }
        }
    }

    .amcheckout-sticky-stopper {
        display: block;
        visibility: hidden;
        margin: 0;
        padding: 0;
        width: 100%;
        height: 0;
    }

    // Modern 3 columns design
    .amcheckout-main-container.-modern.-layout-3columns {
        & {
            display: flex;
            justify-content: space-between;
        }

        .amcheckout-column {
            width: 32%;
        }

        .amcheckout-step-container.-summary {
            &:extend(.amcheckout-main-container.-modern.-layout-2columns .amcheckout-step-container.-summary all);
        }

        .amcheckout-step-container .amcheckout-title {
            cursor: default;
        }

        .amcheckout-step-container .amcheckout-form-login,
        .amcheckout-step-container .form-login {
            .amcheckout-email .amcheckout-wrapper {
                flex: auto;
                width: inherit;
            }

            .amcheckout-password {
                width: 100%;
            }
        }

        .amcheckout-step-container .amcheckout-shipping-address {
            .shipping-address-item,
            .shipping-address-details {
                & {
                    width: 100%;
                }

                &:not(:last-of-type) {
                    margin-bottom: 10px;
                }
            }
        }

        .amcheckout-step-container .amcheckout-shipping-methods {
            & {
                min-width: inherit;
            }

            .amcheckout-method {
                & {
                    width: 100%;
                }

                &:not(:last-of-type) {
                    margin-bottom: 10px;
                }
            }
        }

        .amcheckout-step-container .billing-address-details {
            width: 100%;
        }

        .amcheckout-step-container .amcheckout-payment-methods .payment-method:not(:last-of-type) {
            margin-bottom: 10px;
        }

        .amcheckout-step-container .amcheckout-delivery-date .field.date {
            .control {
                position: relative;
            }

            .input-text._has-datepicker {
                width: 100%;
            }

            ._has-datepicker ~ .ui-datepicker-trigger {
                position: absolute;
                top: 7px;
                right: 7px;
                margin: 0;
            }
        }

        .amcheckout-step-container .amcheckout-summary-container,
        .amcheckout-step-container .amcheckout-additional-options {
            &:extend(.amcheckout-main-container.-modern.-layout-2columns .amcheckout-step-container .amcheckout-summary-container all);
        }
    }
}
