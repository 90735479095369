//
//  Filter items styles
//  __________________________________________________

//
//  Imports
//  --------------------------------------------------

@import '../../_mixins';

//
//  Variables
//  --------------------------------------------------

@amshopby-filter-item__hover__color: var(--color-dark_blue);
@amshopby-filter-item__active__color: var(--color-dark_blue);
@amshopby-filter-item__pressed__color: var(--color-dark_blue);
@amshopby-show-more__color: #070605;
@amshopby-show-more__hover__color: #838382;
@amshopby-filter-content-multiselect__hover__color: #757575;

@amshopby-color-white: #fff;
@amshopby-color-light-grey: #aaa;
@amshopby-color-grey: #666;

//
//  Common
//  --------------------------------------------------

& when (@media-common = true) {
    .filter-options-item .filter-options-content {
        & {
            padding-bottom: 5px;
        }

        .am-swatch-wrapper .swatch-option.image,
        .swatch-attribute.size .swatch-option.selected,
        .swatch-attribute.manufacturer .swatch-option.selected {
            background-position: center;
            background-repeat: no-repeat;
            background-size: contain;
        }

        .range {
            align-items: center;
            display: flex;
            margin-top: 15px;
            text-align: center;
        }

        .items:not(.items-children):not(.am-category-view),
        .am-category-wrapper,
        .amshopby-fromto-wrap {
            margin: 0;
        }

        .am-shopby-form {
            margin: 0 7px;
        }

        .items [class*='am-filter-item'] {
            margin-left: 0;
            padding: 0;
        }

        .count {
            display: inline-flex;
            padding-left: 0;
            color: var(--color-medium_gray);
        }

        .am-shopby-swatch-label .count {
            margin: 0;
            padding: 0;
        }

        .am-shopby-swatch-label .label {
            margin-right: 5px;
        }

        .am-shopby-form {
            margin-bottom: -15px;
            overflow-x: hidden;
            overflow-y: auto;
            padding: 3px;
        }

        .am-shopby-form .swatch-attribute .swatch-option-link-layered:hover {
            background-color: unset;
        }
    }

    .amasty-catalog-topnav .filter-options-item .filter-options-content {
        & {
            padding-bottom: 10px;
        }

        .items:not(.items-children):not(.am-category-view),
        .am-shopby-form,
        .am-category-wrapper,
        .amshopby-fromto-wrap {
            margin: 0;
        }
    }

    .filter-options {
        .item.-is-collapsible.-is-by-click.-folded .-folding {
            display: none;
        }

        .items.-am-overflow {
            overflow-x: hidden;
            overflow-y: auto;
        }

        .items .items-children.-folding {
            list-style: none;
            padding-left: 20px;
            width: 100%;
        }

        .items.-am-singleselect .item.-is-by-click .items-children.-folding .item .amshopby-filter-parent {
            padding-left: 20px;
        }

        .items.-am-multiselect .item.-is-expanded .items-children.-folding {
            padding-left: 0;
        }

        .items .items-children.-folding .item {
            margin: 5px 0;
        }

        .item.-empty-value {
            opacity: .5;
            pointer-events: none;
            user-select: none;
        }

        .ui-widget-overlay {
            background: @amshopby-color-light-grey;
            opacity: .3;
        }

        .ui-widget-shadow {
            box-shadow: 0 0 5px @amshopby-color-grey;
        }

        .am-category-view {
            background-color: @amshopby-color-white;
            border: 1px solid @amshopby-base__border-color;
            box-sizing: border-box;
            display: none;
            margin: 0;
            max-height: 300px;
            overflow-y: auto;
            padding: 10px;
            position: absolute;
            white-space: nowrap;
            width: 100%;
            z-index: 9;
        }

        .items.am-category-view,
        .am-category-view ol.items {
            margin: 0;
        }

        .am-category-view.-am-singleselect .am-collapse-icon {
            & {
                top: 0;
            }

            &:after {
                left: 0;
                right: inherit;
            }
        }

        .am-category-view .item a.amshopby-filter-parent {
            padding-left: 20px;
        }

        .am-category-view.-am-multiselect .item > .am-collapse-icon ~ a.amshopby-filter-parent {
            padding-left: 40px;
        }

        .am-category-view [class*='am-filter-item'] {
            display: inline-block;
            white-space: initial;
        }

        .filter-options-content .rating-summary {
            display: inline-block;
            vertical-align: sub;
        }

        .amshopby-search-box {
            margin: 0 0 15px;
        }

        .am-labels-folding.-am-singleselect .item .am-input,
        [class*='am-filter-items-'].am-labels-folding.-am-singleselect .item:not(.swatch-option-link-layered) input[type='radio'],
        .amshopby-category-dropdown + form .-am-singleselect .item input[type='radio'],
        .am-category-view input[type='radio'] {
            display: none;
        }

        .am-labels-folding.-am-singleselect .item {
            padding: 0;
        }

        .am-category-wrapper .items.-am-multiselect .item.-filter-parent:not(.-is-collapsible),
        .items:not(.items-children) > .item.-is-by-click.-filter-parent ~ .item.-is-expanded {
            margin-left: 20px;
        }
    }

    .filter-options-content .am-ranges .items.-am-multiselect .item {
        & {
            position: relative;
            margin-bottom: 5px;
        }

        [class*='am-filter-item'] {
            padding-left: 20px;

            &:visited {
                color: var(--color-dark_blue);
            }
        }
    }

    .filter-actions .filter-clear {
        & {
            color: @amshopby__primary__color;
            font-size: 14px;
            line-height: 19px;
        }

        &:hover {
            color: @amshopby__hover__color;
            text-decoration: none;
        }

        &:focus,
        &:active {
            color: @amshopby__pressed__color;
        }
    }

    .filter-options-content .item a {
        &:hover {
            background-color: transparent;
            color: @amshopby-filter-item__active__color;
        }

        &:hover .count {
            color: var(--color-medium_gray);
        }

        &:active,
        &:active .count {
            color: var(--color-medium_gray);
            text-decoration: none;
        }
    }

    .filter-options-content {
        .item {
            margin-bottom: 5px;
        }

        .item:first-child {
            margin-top: 0;
        }

        .item:last-child {
            margin-bottom: 0;
        }

        .item > a {
            display: block;
        }

        .items.-am-singleselect .item > input[type='radio'] {
            display: none;
        }

        .items.-am-singleselect .amshopby-link-selected,
        .swatch-option.selected + .am-shopby-swatch-label {
            color: @amshopby-filter-item__active__color;
        }

        .items.-am-singleselect .amshopby-link-selected .count,
        .swatch-option.selected + .am-shopby-swatch-label .count {
            color: var(--color-medium_gray);
        }
    }

    .am-show-more {
        &,
        .filter-options-content & {
            background: transparent;
            border: 0;
            color: @amshopby-show-more__color;
            cursor: pointer;
            display: none;
            font-size: 14px;
            font-weight: @font-weight__regular;
            letter-spacing: .03em;
            line-height: 20px;
            margin: 10px 0 0;
            padding: 0 22px 0 0;
            position: relative;
        }

        .amasty-catalog-topnav .filter-options-content & {
            padding-left: 0;
        }

        .filter-options-content & {
            padding-left: 10px;
        }

        &:hover,
        .filter-options-content &:hover {
            background-color: transparent;
            color: @amshopby-show-more__hover__color;
            text-decoration: none;
        }

        &:active,
        .filter-options-content &:active {
            box-shadow: none;
            color: @amshopby-show-more__color;
        }

        &:before {
            &:extend(.am-collapse-icon:after all);

            top: 0;
        }

        &.-active {
            align-items: center;
            display: inline-flex;
        }

        .am-counter {
            display: inline-block;
            margin: 0 2px;
            opacity: .5;
        }

        &[data-is-hide='false']:before {
            transform: rotate(180deg);
        }
    }

    .amshopby-link-selected {
        color: @amshopby-filter-item__active__color;
    }

    .items[class*='category_ids']:not(.items-children) > .item > a {
        font-weight: 700;
    }

    .filter-options .items:not(.items-children).-am-multiselect > .item.-is-by-click {
        .items-children.-folding {
            padding-left: 0;
        }

        .items-children.-folding .item.-is-expanded {
            margin-left: 20px;
        }
    }

    .am-filter-items-stock .amshopby-link-selected,
    .am-filter-items-rating .amshopby-link-selected {
        text-decoration: none;
    }

    .filter-content [class*='am-filter-item']:hover ~ .amshopby-choice:before {
        border-color: @amshopby-choice-element__hover__border-color;
    }

    .filter-content .items.-am-multiselect [class*='am-filter-item']:hover {
        & {
            color: inherit;
        }

        .count {
            color: var(--color-medium_gray);
        }
    }
}

//
//  Mobile
//  --------------------------------------------------

.media-width(@extremum, @break) when (@extremum = 'max') and (@break = @screen__m) {
    .filter-options-content .am-ranges .items.-am-multiselect .item [class*='am-filter-item'] {
        padding-left: 35px;
    }

    .filter-options {
        .am-labels-folding .item .am-collapse-icon ~ [class*='am-filter-item-'],
        .am-labels-folding .item {
            padding-left: 35px;
        }

        .items:not(.items-children).-am-multiselect > .item.-is-by-click .items-children.-folding .item.-is-expanded,
        .am-category-wrapper .items.-am-multiselect .item.-filter-parent:not(.-is-collapsible),
        .items:not(.items-children) > .item.-is-by-click.-filter-parent ~ .item.-is-expanded {
            margin-left: 35px;
        }
    }

    .filter-options-item .filter-options-content .items [class*='am-filter-item'] {
        padding: 7px 0;
    }

    .am-labels-folding .am-collapse-icon,
    .filter-options .amshopby-category-dropdown + form .items.-am-multiselect .item .am-collapse-icon {
        margin: 7px 0;
    }

    .filter-options [class*='am-filter-items-'].am-labels-folding .item:not(.swatch-option-link-layered),
    .filter-options .amshopby-category-dropdown + form .item {
        .am-collapse-icon ~ .am-input,
        .am-collapse-icon ~ input[type='radio'],
        .am-collapse-icon ~ input[type='checkbox'],
        .am-collapse-icon ~ input[type='checkbox'] + .amshopby-choice {
            left: 35px;
        }
    }

    .filter-options {
        .items.-am-singleselect .item.-is-by-click .items-children.-folding .item .amshopby-filter-parent,
        .amshopby-category-dropdown + form .items.-am-multiselect .item .items-children.-folding,
        .am-category-wrapper .items .items-children.-folding,
        .am-category-view .item a.amshopby-filter-parent {
            padding-left: 35px;
        }

        .am-category-view.-am-multiselect .item > .am-collapse-icon ~ a.amshopby-filter-parent {
            padding-left: 70px;
        }

        .am-category-view.-am-singleselect .am-collapse-icon {
            margin: 7px 0;
        }
    }

    .am-collapse-icon:after {
        width: 20px;
    }

    .filter-content .active .am-show-more {
        &:before {
            width: 12px;
        }

        &.-active {
            margin-bottom: 10px;
        }
    }
}
