//
//    Common
//--------------------------------------
@spinner-color: #999999;
@product-image__width: 80px;
@product-image__height: 80px;

& when (@media-common = true) {
    @top-color:     #00ADE7;
    @header-bg:      #efefef;
    @hr-color:       #f6f6f6;
    @hover-bg:       #f8f8f8;
    @mute-color:     @top-color;
    @description__text-color: #7E7E7E;
    @text-color:     #333333;
    @higlight-color: @top-color;

    .mst-searchautocomplete__autocomplete {
        display:       none !important;
        position:      absolute;
        top:           3.1rem;
        left:          0;
        box-sizing:    border-box;
        background:    #ffffff;
        min-height:    3.5rem;
        z-index:       1000;
        text-align:    left;
        overflow:      hidden;
        box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.29);
        margin-top:    7px;

        &._active {
            display: block !important;
            min-height: 50px;
        }

        .mst-searchautocomplete__spinner {
            display:  none;
            position: absolute;
            top:      15px;
            right:    15px;
        }

        &._loading {
            .mst-searchautocomplete__spinner {
                display: block;
            }
        }

        .mst-searchautocomplete__empty-result {
            background: @header-bg;
            text-align: center;
            padding:    8px 10px;
            color:      @mute-color;
            font-size:  1.2rem;
            display:    block;
        }

        .mst-searchautocomplete__show-all {
            text-align: center;

            a {
                font-weight: 600;
                font-size: 14px;
                line-height: 18px;
                padding: 15px 0 15px 0;
                color:       @mute-color;
                display:     block;
            }

            .price-counts {
                font-weight: 400;
                color: #132A47;
            }
        }

        .mst-searchautocomplete__index-title {
            display: none;
            border-bottom:  1px solid @header-bg;
            padding:        10px 0 9px 0px;
            margin:         0 10px;
            text-align:     left;
            font-size:      1.2rem;
            line-height:    1.2rem;
            color:          @mute-color;
            text-transform: uppercase;
            font-weight:    700;
        }

        .mst-searchautocomplete__close {
            display: none;
        }

        .mst-searchautocomplete__index {

            &.magento_catalog_product {
                padding-top: 10px;

                ul {
                    grid-auto-rows: minmax(72px, 91px);
                    // grid-row-gap: 18px;

                    li {
                        padding: 5px 0;

                        &:last-child {
                            &:after {
                                display: none;
                            }
                        }
                    }
                }
            }

            &.magento_cms_page {
                margin-top: 15px;
                border-top: 1px solid #e5e5e5;
                padding-top: 15px;

                ul {
                    // grid-auto-rows: minmax(20px, 20px);
                    grid-row-gap: 0;

                    li {
                        a.title {
                             line-height: 20px;
                             height: 20px;
                             display: block;
                             padding: 3px 20px;
                             font-weight: 400;

                             &:hover {
                                text-decoration: none;
                             }

                             .mst-searchautocomplete__highlight {
                                color: #333333;
                                font-weight: 700;
                             }
                             
                        }

                        &:hover {
                            // background-color: transparent;
                        }
                    }
                }
            }

        }

        ul {
            display: grid;
            overflow: hidden;
            grid-template-columns: 1fr;
            grid-template-rows: inherit;
            justify-content: center;
            list-style: none;
            margin: 0;
            padding: 0;
            height: auto;
            overflow:   auto;

            li {
                .lib-clearfix();

                margin: 0!important;

                cursor:  pointer;

                a.title {
                    font-weight:     600;
                    margin-bottom:   0;
                    color:           @text-color;
                    text-decoration: none;
                }

                &._active {
                    background: @hover-bg;

                    a.title {
                        text-decoration: underline;
                    }
                }
            }
        }
    }

    .mst-searchautocomplete__index {
        &.popular {
            .index-title {
                background: none;
                text-align: left;
                color:      #999999;
            }

            ul {
                li {
                    float:   left;
                    padding: 10px 10px 10px 10px;
                    margin:  0;

                    a {
                        font-size: 1.3rem;
                        color:     #575757;
                    }

                    &:hover {
                        background: none;
                        cursor:     pointer;

                        a {
                            text-decoration: underline;
                        }
                    }
                }
            }
        }
    }

    .mst-searchautocomplete__item {

        &.magento_catalog_product {
            display:       flex;
            flex-direction: column;
            flex-wrap: wrap;
            align-items: start;
            justify-content: center;
            position: relative;

            // &::after {
            //     content: '';
            //     height: 1px;
            //     background-color: #f6f6f6;
            //     width: calc( 100% - 40px );
            //     position: absolute;
            //     top: auto;
            //     bottom: 0;
            //     left: 20px;
            // }

            .mst-product-image-wrapper {
                height:       @product-image__height;
                width:        @product-image__width;
                margin: 0 20px;

                img {
                    height:     @product-image__height;
                    max-width:  @product-image__width;
                    display:    block;
                    margin:     auto;
                    object-fit: contain;
                }
            }

            .title {
                display:       block;
                margin-bottom: 4px;
                font-weight: 700;
                font-size: 14px;
                line-height: 18px;
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden; 
                width: 260px; 

                a {
                    color: @text-color;
                }

                .sku {
                    color: @mute-color;
                }
            }

            .meta {
                width: calc( 100% - 120px );
                margin-bottom: 8px;
            }

            .store {
                padding-top: 8px;

                .price-box.price-final_price .special-price .price {
                    color: #C71B21!important;
                }

                .price-final_price {
                    display: flex;

                    .special-price {
                        margin-right: 8px;
                        margin-left: 0;
                        margin-top: 0;
                        margin-bottom: 0;
                    }

                    .old-price, .price {
                        color: #132A47;
                    }

                    .price-to {
                        display: none;
                    }

                    .price-from {
                        .old-price {
                            display: none;
                        }
                    }

                    .minimal-price-link {
                        display: none;
                    }
                }
            }

            .description {
                font-size: 10px;
                line-height: 14px;
                color: @description__text-color;
                max-height: 28px;
                max-width: 280px;
                overflow:      hidden;
                margin-bottom: 0;
            }

            .price {
                display:     block;
                font-weight: 400!important;
                font-size: 14px!important;
                line-height: inherit!important;       
                white-space: nowrap;

                .price-label {
                    margin-right: 1rem;
                }
            }

            .product-reviews-summary {
                margin: 0;

                .reviews-actions {
                    display: none;
                }
            }

            .to-cart {
                float:         right;;
                margin-top:    .5rem;
                margin-bottom: .5rem;
            }

            .inStock{
                color: #76bd76;
            }

            .outOfStock{
                color: #a96565;
            }

            &._active {
                background: @hover-bg !important;

                .title {
                    a {
                        text-decoration: underline;
                    }
                }
            }

        }

        &.magento_search_query {
            a {
                float: left;
            }

            .num_results {
                float: right;
                color: @mute-color;
            }
        }

        a.title {
            word-break: break-word;

            i {
                color:      @mute-color;
                display:    inline-block;
                margin:     0 .5rem;
                font-style: normal;

            }

        }
    }

    .mst-searchautocomplete__highlight {
        font-weight: @font-weight__semibold;
        color:       @higlight-color;
    }

    .mst-searchautocomplete__spinner {
        @size:    20px;
        position: relative;
        width:    @size;
        height:   @size;
        margin:   auto;

        .spinner-item {
            box-sizing:    border-box;
            display:       block;
            position:      absolute;
            width:         @size;
            height:        @size;
            margin:        0;
            border:        2px solid #ffffff;
            border-radius: 50%;
            animation:     lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
            border-color:  @spinner-color transparent transparent transparent;

            &.spinner-item-1 {
                animation-delay: -0.45s;
            }

            &.spinner-item-2 {
                animation-delay: -250ms;
            }

            &.spinner-item-3 {
                animation-delay: -0.15s;
            }
        }
    }

    @keyframes lds-ring {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }


    //
    //    Desktop
    //--------------------------------------
    @media (min-width: 768px) {
        .mst-searchautocomplete__autocomplete {
            width:     100%;
            // min-width: 808px;

            &._active {
                display: block !important;

                &.mst-2-cols {
                    width:    720px !important;
                    position: absolute;

                    .mst-searchautocomplete__index {
                        clear:          left;
                        float:          left;
                        display:        block;
                        position:       relative;
                        max-width:      240px;
                        width:          240px;
                        padding-bottom: 2rem;
                        box-sizing:     border-box;
                    }

                    div.magento_catalog_product {
                        width:          480px;
                        max-width:      480px;
                        clear:          right;
                        float:          right;
                        border-left:    1px solid #f6f6f6;
                        border-right:   unset;
                        padding-bottom: 0;
                    }

                    .mst-searchautocomplete__show-all {
                        clear: both;
                    }
                }
            }
        }
    }

    @media (max-width: 1180px) {
        .mst-searchautocomplete__autocomplete {
            min-width: initial;

            ul {
                overflow: hidden;
                grid-template-columns: 1fr;
                grid-template-rows: inherit;
                grid-row-gap: 18px;
            }
        }

        .mst-searchautocomplete__item.magento_catalog_product {
            &::after {
                top: 115px;
                right: 0;
                left: 0;
                margin: auto;
                width: 300px;
            }

            .title {
                width: 200px;
            }

            .meta {
                margin-bottom: 6px;
                margin-top: -4px;
                max-height: 64px;
                padding-right: 1rem;
            }

            .description {
                max-width: 73%;
                max-height: 42px;

                > p:first-child {
                    display: block;
                }

                p {
                    display: none;
                }
            }
        }
    }


    //
    //    Mobile
    //--------------------------------------
    @media (max-width: 768px) {
        .mst-searchautocomplete__autocomplete {
            margin: 10px auto;
            width: 346px!important;
            left: 0!important;
            right: 0;

            .mst-searchautocomplete__show-all a {
                padding: 37px 0 12px 0;
                position: relative;

                ::before {
                    content: '';
                    height: 1px;
                    background-color: #f6f6f6;
                    width: 328px;
                    position: absolute;
                    top: -37px;
                    bottom: 0;
                    margin: auto;
                    left: 20px;
                }
            }

            .mst-searchautocomplete__close {
                display:     none;
                position:    absolute;
                right:       6px;
                top:         0;
                font-weight: bold;
                color:       #999999;
                font-size:   1.6rem;
                padding:     10px;
                line-height: 1rem;
                cursor:      pointer;
            }

            ul {
                padding: 17px 18px 0 18px;
                margin: 0;
                overflow: hidden;
            }
        }

        .mst-searchautocomplete__item.magento_catalog_product {
            justify-content: start;

            p {
                margin-bottom: 0;
            }

            &::after {
                top: 95px;
                right: 0;
                left: 0;
                margin: auto;
                width: 328px;
            }

            .title {
                width: 220px;
            }

            .description {
                max-width: 220px;
                max-height: 42px;

                > p:first-child {
                    display: block;
                }

                p {
                    display: none;
                }
            }

            .store {
                padding-top: 0;

                span.price {
                    line-height: 19px!important;
                }
            }

            .mst-product-image-wrapper {
                margin: 0 18px 0 0;
            }
        }
    }
}
