//----
// @category  : Ubertheme
// @package   : Ubertheme_UbMegaMenu
// @author    : Ubertheme.com
// @copyright : Copyright 2009-2021 Ubertheme
//---------------------------

//
//  Common
//  _____________________________________________

& when (@media-common =true) {
    .page-footer {
        .ub-footer-mega-menu {
            .lib-clearfix();
            border-bottom: 1px solid #ddd;
            padding-bottom: 20px;
            margin-bottom: 20px;
        }
        .level0 {
            > li.mega {
                &.dynamic-width {
                    .lib-vendor-prefix-display(flex);
                    .lib-vendor-prefix-flex-wrap(wrap);
                }
                &.has-child {
                    > .mega {
                        padding: 0
                    }
                }
            }
            ul.level1 {
                >li.mega {
                    .content {
                        margin-top: 10px
                    }
                    &.group {
                        > a {
                            .lib-css(font-weight, @font-weight__bold);
                            text-transform: uppercase;
                        }
                    }
                }
                
            }
        }
    }
}

//
//  Mobile
//  _____________________________________________

.media-width(@extremum, @break) when (@extremum ='max') and (@break = @screen__m) {
    .page-footer {
        .level0 {
            > li.mega {
                width: 100%;
                border-bottom: 0;
                &.dynamic-width {
                    .lib-vendor-prefix-flex-direction(column);
                }
                .mega-col {
                    width: 100% !important;
                    margin-bottom: @indent__s;
                }
                .menu-group-link {
                    display: none
                }
            }
        }
    }
}

//
//  Tablet and Desktop
//  _____________________________________________

.media-width(@extremum, @break) when (@extremum ='min') and (@break = @screen__m) {
    .page-footer {
        .level0 {
            > li.mega {
                &.dynamic-width {
                    .lib-vendor-prefix-flex-direction(row);
                }
            }
        }
    }
}