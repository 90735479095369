//
//  Amasty One Step Checkout Helpers
//  _____________________________________________

// Selectable items helper
._amcheckout-selectable-container {
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    justify-content: space-between;
}

._amcheckout-selectable-item {
    & {
        display: block;
        justify-content: space-between;
        box-sizing: border-box;
        padding: 0;
        width: 100%;
        border: 1px solid #d4d4d4;
        border-radius: 2px;
        cursor: pointer;
        transition: var(--default-transition);
        overflow: hidden;
        border-radius: var(--border-radius);
    }

    &:not(:last-of-type) {
        margin-bottom: 15px;
    }

    &:hover {
        background: #f9f9f9;
        border-color: #B5B5B5;
    }

    &.-selected {
        border-color: #00ADE7;
        background: rgba(0, 173, 231, 0.05);
    }
}

@media all and (min-width: @screen__m) {
    ._amcheckout-selectable-item {
        & {
            width: 48%;
        }

        &:nth-last-child(-n + 2) {
            margin-bottom: 0;
        }
    }
}
