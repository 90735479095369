& when (@media-common = true) {
    .mstSocialLogin__login {
        display: flex;
        flex-wrap: wrap;
        background: #f4f4f4;
        padding: 1rem;
        justify-content: center;

        .mstSocialLogin__button {
            margin: .5rem .5rem;
        }

        &._createBefore,
        &._loginBefore {
            margin-bottom: 1rem;
        }

        &._createAfter,
        &._loginAfter {
            margin-top: 1rem;
        }
    }
}
