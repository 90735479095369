//----
// @category  : Ubertheme
// @package   : Ubertheme_UbMegaMenu
// @author    : Ubertheme.com
// @copyright : Copyright 2009-2021 Ubertheme
//---------------------------

//
//  Tablet Landscape and Desktop
//  _____________________________________________
.media-width(@extremum, @break) when (@extremum ='min') and (@break = @screen__l) {


    .nav-sections {
        .nav-sections-items {
            display: block !important;
            margin: auto;
            .lib-css(max-width, @layout__max-width);
        }

        .nav-sections-item-content {
            >.drilldown {
                display: block;
            }
        }
    }

    // Sticky style
    .nav-before-open {
        overflow: inherit;
        body {
            height: auto;
        }
        .page-wrapper {
            left: 0;
            &.nav-off-canvas {
                overflow: inherit;
            }
        }
    }

    //
    // Style when horizontal-root class not ready
    //  ---------------------------------------------
    .nav-sections .level0:not(.horizontal-root) {
        .lib-vendor-prefix-display(flex);
        .lib-vendor-prefix-flex-wrap(wrap);
        justify-content: space-between;
        >li.mega {
            // position: static;

            >.mega {
                padding: 0;
                line-height: 18px;
                position: relative;
                span.menu-icon {
                    left: 50%;
                    margin-left: -16px;
                    top: -10px;
                }
            }

            &.has-child {
                >.mega {
                    >span.menu-title {
                        display: flex;
                        flex-direction: column;
                        &:after {
                            margin-left: 10px;
                            margin-top: -1px;
                            font-size: 50px;
                        }
                    }
                }

                &.mega-hover {
                    >.mega {
                        .lib-css(background, @secondary__color);
                        .lib-css(color, @active__color);
                        text-decoration: none;
                    }
                }
            }

            .child-content {
                display: none;
            }
        }
    }

    //
    //  Level - 0
    //  ---------------------------------------------
    .level0.horizontal-root {
        display: grid;

        >li.mega {
            // position: static;

            >.mega {
                padding: 0 20px;
                line-height: 50px;
                position: relative;
                .ub-nav-sticky.sticky-fired &  {
                    padding: 0 10px;
                }
                &.ui-state-active,
                &:active,
                &:focus,
                &:hover {
                    text-decoration: none;
                }

                span.menu-icon {
                    left: 50%;
                    margin-left: -16px;
                    top: -10px;
                }

                &.active {
                    .lib-css(color, @active__color);
                }
            }

            &.has-child {
                >.mega {
                    >span.menu-title {
                        &:extend(.ub-icon-down-l all);
                    }
                }

                &.mega-hover {
                    >.mega {
                        .lib-css(color, @active__color);
                        text-decoration: none;
                    }
                }
            }

            .menu-group-link {
                display: none;
            }

            //
            //  Sub-Menu
            //  ---------------------------------------------
            .child-content {
                background: @color-white;
                border: 1px solid @secondary__color;
                display: none;
                position: absolute;
                top: auto;
                z-index: 99;

                .child-content-inner {
                    padding: 0 20px 20px;
                    .ub-box-sizing(content-box);
                }

                li.mega {
                    &.group {
                        .lib-vendor-prefix-display(flex);
                        .lib-vendor-prefix-flex-wrap(wrap);

                        > a.mega {
                            color: @primary__color;
                            width: 100%;
                        }
                    }
                }
            }

            //  Level - 1
            //  ---------------------------------------------
			ul.level1 {
                > li.mega {
                    &.has-child {
                        > .mega {
                            > span.menu-title {
                                &:extend(.ub-icon-next-l all);
                                position: relative;
                                width: 100%;

                                &:after {
                                    position: absolute;
                                    top: 0;
                                    right: 0;
                                    display: none;
                                }
                            }
                        }

                        .child-content {
                            display: none !important;
                        }
                    }
                }
            }

            //  Level - 2
            //  ---------------------------------------------
            ul.level2 {
                > li.mega {
                    &.has-child {
                        > .mega {
                            > span.menu-title {
                                &:extend(.ub-icon-next-l all);
                                position: relative;
                                width: 100%;

                                &:after {
                                    position: absolute;
                                    top: 0;
                                    right: 0;
                                }
                            }
                        }
                    }
                }
            }

            //  Level - 3
            //  ---------------------------------------------

            ul.level3 {
                > li.mega {
                    &.has-child {
                        > .mega {
                            > span.menu-title {
                                position: relative;
                                width: 100%;
                                &:extend(.ub-icon-next-l all);
                                &:after {
                                    position: absolute;
                                    top: 0;
                                    right: 0;
                                }
                            }
                        }
                    }
                }
            }

            //  Level - 4
            //  ---------------------------------------------
            ul.level4 {
                > li.mega {
                    &.has-child {
                        > .mega {
                            > span.menu-title {
                                position: relative;
                                width: 100%;
                                &:extend(.ub-icon-next-l all);

                                &:after {
                                    position: absolute;
                                    top: 0;
                                    right: 0;
                                }
                            }
                        }
                    }
                }
            }

            &.style-default,
            &.style-1 {
                .child-content {
                    left: 0;
                }
            }

            &.style-default {
                position: relative;

            }

            &.style-1 {
                position: static;
                .child-content {
                    .ub-nav-sticky.sticky-fired &  {
                        left: auto;
                        right: 20px;
                    }
                }
            }

            &.style-2 {
                position: static;

                .child-content {
                    left: 100px;
                    .ub-nav-sticky.sticky-fired &  {
                        left: auto;
                        right: 20px;
                    }
                    .child-content-inner {
						padding: 0 0 20px;
                    }

                }
            }

            &.dynamic-width {
                .child-content {
                    left: 0;
                    padding: 20px 0;
                    width: 100%;

                    .child-content-inner {
                        padding: 0 20px;
                        .ub-box-sizing(border-box);

                        .intro-megamenu {
                            .func-01 {
                                p {
                                    // float: right;
                                    margin-left: 0;
                                    .lib-font-size(@font-size__base);
                                    line-height: 25px;
                                }

                                .title {
                                    .lib-font-size(@font-size__base * 1.29);
                                    .lib-css(font-weight, @font-weight__bold);
                                    line-height: 25px;
                                }

                                img {
                                    margin-bottom: @indent__m;
                                    max-width: 400px;
                                }
                            }
                        }
                    }
                }
            }

            &.style-tabs {
                .child-content {
                    .child-content-inner {
                        .ub-box-sizing(border-box);

                        ul.level2 {
                            >li.mega {
                                position: static;

                                &.has-child {
                                    .child-content {
                                        .ub-box-sizing(border-box);
                                        background: @color-white;
                                        border: 0;
                                        left: 200px;
                                        max-width: 80%;
                                        padding: 0 0 0 20px;
                                        top: 0;
                                        .ub-box-shadow(none);

                                        .child-content-inner {
                                            padding: 0;
                                            .ub-box-sizing(border-box);
                                        }
                                    }

                                    a.mega,
                                    span.mega {
                                        max-width: 170px;
                                        padding-top: 0 !important;
                                        &:hover {
                                            .lib-css(color, @active__color);
                                        }
                                    }
                                }
                            }
                        }

                        ul.level3 {
                            >li.mega {
                                &.group {
                                    >.mega {
                                        background: none !important;
                                        background-color: transparent !important;
                                        color: @primary__color;
                                        line-height: 100%;
                                        padding: 0;
                                        padding-bottom: @indent__s  !important;

                                        &.active {
                                            .lib-css(color, @active__color);
                                        }
                                    }
                                }
                            }
                        }

                        //Style for sample data
                        .collections {
                            .block.widget {
                                margin-bottom: 0px;
                                margin-top: 0px;

                                .products-grid {
                                    li.product-item {
                                        .lib-css(width, calc(100% / 3)) !important;
                                    }
                                }
                            }

                            .product-item {
                                .product-item-info {
                                    a {
                                        background: none !important;
                                        max-width: inherit !important;
                                    }
                                }
                            }
                        }
                    }
                }

                ul.level2 {
                    >li.mega {
                        .mega {
                            color: @primary__color;
                            font-weight: 600;
                            line-height: 35px;
                            padding: 0 10px;
                        }

                        &.has-child {
                            .child-content {
                                display: none;
                            }

                            &.active {
                                >.child-content {
                                    display: block !important;
                                }

                                .has-child {
                                    background-color: @secondary__color  !important;
                                    .lib-css(color, @active__color);
                                }

                                >li.mega {
                                    .mega {
                                        color: @color-white;
                                    }
                                }
                            }
                        }
                    }
                }

                ul.level3 {
                    >li.mega {
                        .mega {

                            &:hover,
                            &.active {
                                color: @active__color;
                            }
                        }
                    }
                }

                ul.level4 {
                    >li.mega {
                        padding: 0 !important;
                        .mega {
                            font-weight: 400;
                            padding: 0;

                            &:hover,
                            &.active {
                                color: @active__color;
                            }
                        }
                    }
                }

                .child-content {
                    .child-content-inner {
                        ul.level2 {
                            >li.mega {
                                &.has-child {
                                    .child-content {
                                        max-width: 80%;
                                        left: 190px;
                                    }
                                }
                            }
                        }

                        .collections {
                            .block.widget {
                                .products-grid {
                                    li.product-item {
                                        margin-bottom: @indent__m  !important;
                                    }
                                }
                            }
                        }
                    }
                }
            }

            &.style-tabs-hz {
                .child-content {
                    padding: 0;

                    .child-content-inner {
                        padding: 0;
                        .ub-box-sizing(border-box);

                        ul.level2 {
                            >li.mega {
                                display: inline-block;
                                float: none;
                                position: static;
                                width: auto;

                                &.has-child {
                                    .child-content {
                                        background: @color-white;
                                        border: 0;
                                        left: 200px;
                                        max-width: 80%;
                                        padding: 20px 0 0 !important;
                                        top: 0;
                                        width: 100% !important;
                                        .ub-box-shadow(none);
                                        .ub-box-sizing(border-box);

                                        .child-content-inner {
                                            padding: 0;
                                        }
                                    }

                                    a.mega,
                                    span.mega {
                                        max-width: 130px;
                                        padding-top: 0 !important;

                                        span.menu-title {
                                            .lib-font-size(@font-size__base);
                                            padding-right: 0;
                                            width: auto;

                                            &:after {
                                                display: none;
                                            }
                                        }
                                    }

                                    &.active {

                                        a.mega,
                                        span.mega {
                                            span.menu-title {
                                                color: @active__color;
                                            }
                                        }
                                    }
                                }
                            }
                        }

                        ul.level3 {
                            >li.mega {
                                &.group {
                                    >.mega {
                                        background-color: transparent !important;
                                        line-height: 100%;
                                        margin-bottom: 0;
                                        padding: 0;
                                        padding-bottom: 10px !important;

                                        span.menu-icon {
                                            float: left;
                                            height: 32px;
                                            line-height: 32px;
                                            margin: 0 10px 0 0;
                                            position: static;

                                            img {
                                                border: 2px solid @border-color__base;
                                                .ub-border-radius(100%);
                                            }
                                        }

                                        span.menu-title {
                                            border-bottom: 0 !important;
                                            color: inherit !important;
                                            line-height: inherit !important;
                                        }

                                        &:hover {
                                            img {
                                                border-color: @secondary__color;
                                            }
                                        }

                                        &.active {
                                            color: @active__color;
                                        }
                                    }
                                }
                            }
                        }

                        //Style for sample data
                        .collections {
                            .block.widget {
                                margin-bottom: 0px;
                                margin-top: 0px;

                                .products-grid {
                                    li.product-item {
                                        .lib-css(width, calc(100% / 3)) !important;
                                    }
                                }
                            }

                            .product-item {
                                .product-item-info {
                                    a {
                                        background: none !important;
                                        max-width: inherit !important;
                                    }
                                }
                            }
                        }
                    }
                }

                ul.level2 {
                    >li.mega {
                        >.mega {
                            color: @primary__color;
                            font-weight: 600;
                            line-height: 50px;
                            padding: 0 20px;
                            position: relative;
                            text-align: center;
                            transition: all 0.4s linear;

                            span.menu-icon {
                                border: 2px solid transparent;
                                display: block;
                                height: 80px;
                                left: 0;
                                margin: auto;
                                margin-left: 0;
                                max-width: 80px;
                                position: static;
                                top: 0;
                                width: 100%;
                                .ub-border-radius(50%);
                            }

                            &:hover {
                                span.menu-icon {
                                    border-color: @secondary__color;
                                }
                            }
                        }

                        &.has-child {
                            .child-content {
                                display: none;
                            }

                            &.active {
                                >.child-content {
                                    display: block;
                                }

                                a.has-child {
                                    >span.menu-title {
                                        border-bottom: 1px solid @color-black;
                                        display: inline-block;
                                        line-height: 50px;
                                        position: relative;
                                        z-index: 111;
                                    }
                                }

                                >li.mega {
                                    .mega {
                                        color: @active__color;
                                    }
                                }
                            }
                        }
                    }
                }

                ul.level3 {
                    >li.mega {
                        .mega {

                            &:hover,
                            &.active {
                                color: @active__color;
                            }
                        }
                    }
                }

                ul.level4 {
                    >li.mega {
                        .mega {
                            font-weight: 400;
                            padding: 0;

                            &:hover,
                            &.active {
                                color: @active__color;
                            }
                        }
                    }
                }

                .child-content {
                    .child-content-inner {
                        ul.level2 {
                            >li.mega {
                                &.has-child {
                                    .child-content {
                                        .ub-box-shadow(none);
                                        border-top: 1px solid @border-color__base;
                                        left: 0;
                                        max-width: 100%;
                                        min-height: 100% !important;
                                        padding: 20px !important;
                                        top: 50px;
                                    }
                                }
                            }
                        }

                        ul.level3 {
                            >li.mega {
                                &.has-child {
                                    .child-content {
                                        border-top: 0;
                                        display: block;
                                        margin: 0;
                                        padding: 0 !important;
                                        position: static;
                                        top: 0;
                                    }
                                }
                            }
                        }

                        .collections {
                            .block.widget {
                                .products-grid {
                                    li.product-item {
                                        .lib-css(width, calc(100% / 2));
                                    }
                                }
                            }
                        }

                        .hot-products {
                            .block.widget {
                                margin-top: 0;
                            }

                            .block-title {
                                line-height: 1.33;

                                strong {
                                    .lib-font-size(@font-size__base);
                                }
                            }
                        }
                    }
                }
            }

            &.has-child {
                &.on-hover {
                    &:focus,
                    &:hover {
                        > .child-content {
                            display: block;
                        }
                    }
                }

			}
        }

        .mega-with-thumb {
            ul.mega-menu {
                li {
                    .lib-css(width, calc(100% / 5));
                    .ub-box-sizing(border-box);
                    margin: 0 !important;
                    padding: 0 15px !important;

                    a.mega {
                        padding: 0 !important;
                    }
                }
            }
        }
    }

    .cms-index-index {
        .level0.horizontal-root {
            >li.mega {
                &.has-child{
                    &.mega-hover {
                        > .child-content {
                            display: block;
                            max-height: calc( 100vh - var(--header-height) );
                        }
                    }
                }
            }
        }
    }

    body:not(.cms-index-index) {
        .level0.horizontal-root {
            >li.mega {
                &.has-child{
                    &:hover, &:active {
                        > .child-content {
                            display: block;
                        }
                    }
                }
            }
        }
    }

     // Sticky style
     .ub-nav-sticky {
        &.sticky-fired {
            &:not(.nav-off-canvas) {
                ul.level0 {
                    &.horizontal-root {
                        position: static;
                        >li.mega  {
                            .child-content {
                                max-width: 980px;
                            }
                            &.style-default {
                                position: static;
                                .child-content {
                                    left: auto;
                                    right: 20px;
                                    top: 60px;
                                }
                            }
                        }
                        ul.level1 {
                            li.mega {
                                &.has-child {
                                    .child-content  {
                                        top: 0;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
     }
}

@media screen and (min-width: 1200px) {
    // Sticky style
    div.ub-nav-sticky {
        &.sticky-fired {
            &:not(.nav-off-canvas) {
                ul.level0 {
                    &.horizontal-root {
                        >li.mega  {
                            .child-content {
                                max-width: 1220px;
                            }
                            &.style-default {
                                position: relative;
                                .child-content {
                                    right: auto;
                                    left: 0;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

//
//  Desktop min-width: 1200px
//  _____________________________________________
.media-width(@extremum, @break) when (@extremum ='min') and (@break = @screen__xl) {

    //
    //  Level - 0
    //  ---------------------------------------------
    ul.level0.horizontal-root {
        list-style: none;
        margin: 0;
        padding: 0;
        position: relative;

        >li.mega {
            position: relative;

            >.mega {
                .ub-nav-sticky.sticky-fired &  {
                    padding: 0 20px;
                }
            }

            &.style-default,
            &.style-1 {
                position: relative;
            }
            &.style-1 {
                .child-content {
                    .ub-nav-sticky.sticky-fired &  {
                        left: 0;
                        right: auto;
                    }
                }
            }

            &.style-2 {
                position: relative;

                .child-content {
                    left: 0px;
                }
                .ub-nav-sticky.sticky-fired &  {
                    left: 0;
                    right: auto;
                }
            }

            //
            //  FullWidth for Submenu
            //  ---------------------------------------------
            &.dynamic-width {
                position: static;

                .child-content {
                    left: 0;
                    width: 100%;

                    .child-content-inner {

                        // Custom Content
                        .cms-content-important {
                            .lib-font-size(@font-size__base * 1.15);
                            margin: 0;
                        }

                        .intro-megamenu {
                            .intro {
                                margin-bottom: 20px;
                            }

                            .func-01 {
                                margin-bottom: 20px;

                                p {
                                    .lib-font-size(@font-size__base * 1.15);
                                    line-height: 30px;
                                }

                                .title {
                                    .lib-font-size(@font-size__base * 3.3);
                                    .lib-css(font-weight, @font-weight__bold);
                                    line-height: 55px;
                                }

                                img {
                                    max-width: inherit;
                                    float: right;
                                    margin-left: 20px;
                                }
                            }
                        }
                    }
                }

                ul.level1 {
                    >li.mega {
                        &.has-child {
                            >.mega {
                                >span.menu-title {
                                    display: inline-block;
                                    padding-right: 20px;
                                }
                            }

                            .child-content {
                                left: auto;
                            }
                        }
                    }
                }

                ul.level2 {
                    >li.mega {
                        &.has-child {
                            >.mega {
                                >span.menu-title {
                                    display: inline-block;
                                }
                            }

                            .child-content {
                                left: auto;
                            }
                        }
                    }
                }

                ul.level3 {
                    >li.mega {
                        &.has-child {
                            >.mega {
                                >span.menu-title {
                                    display: inline-block;
                                    padding-right: 20px;
                                }
                            }

                            .child-content {
                                left: auto;
                            }
                        }
                    }
                }

            }


            //
            //  Tab Horizontal
            //  ---------------------------------------------
            &.style-tabs-hz {
                .child-content {
                    .child-content-inner {
                        ul.level2 {
                            >li.mega {
                                &.has-child {

                                    a.mega,
                                    span.mega {
                                        max-width: inherit;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        //Style custom show demo Mega-with-thumbs
        .mega-with-thumb {
            ul.mega-menu {
                li {
                    width: 20%;
                }
            }
        }
    }

    // Sticky style
    div.ub-nav-sticky {
        &.sticky-fired {
            &:not(.nav-off-canvas) {
                ul.level0 {
                    &.horizontal-root {
                        >li.mega  {
                            .child-content {
                                max-width: 1240px;
                            }
                            &.style-2 {
                                .child-content {
                                    right: 0;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
