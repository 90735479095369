//
//  Mixins
//  ____________________________________________

.transition(@del: .3) {
    transition: ~'@{del}s all ease-in';
}

.am-flex(
    @horiz-pos: none,
    @vert-pos: none,
    @wrap: none,
    @inline: none
) {

    & when (@inline = none) {
        display: flex;
    }

    & when not (@inline = none) {
        display: @inline;
    }

    & when not (@horiz-pos = none) {
        justify-content: @horiz-pos;
    }

    & when not (@vert-pos = none) {
        align-items: @vert-pos;
    }

    & when not (@wrap = none) {
        flex-wrap: @wrap;
    }
}

.am-visually-hidden {
    border: 0;
    clip: rect(0, 0, 0, 0);
    height: 0;
    margin: 0;
    opacity: 0;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 0;
}

.am-word-break {
    & {
        word-break: break-word;
        word-wrap: break-word;
    }

    .ie11 & {
        word-break: break-all;
    }
}

.amshopby-button-mixin(@color: @amshopby__primary__color) {
    & {
        &:extend(.action.primary all);
        .am-word-break;

        background: @color;
        border-radius: 3px;
        color: #fff;
        font-size: 16px;
        letter-spacing: -.015em;
        line-height: 22px;
        min-height: 40px;
        text-align: center;
        transition: background .2s ease-in-out, border-color .2s ease-in-out;
    }

    &:not(.primary) {
        border-radius: 3px;
    }

    &:not(.primary),
    &:not(.primary):active {
        box-shadow: none;
    }

    &:hover {
        background: lighten(@color, 13%);
        border-color: lighten(@color, 13%);
    }

    &:active,
    &:focus {
        background: darken(@color, 10%);
        border-color: darken(@color, 10%);
    }
}

.amshopby-scrollbar(
    @color: #adadad,
    @second-color: #f0f0f0,
    @direction: y,
    @width: 6px,
    @shadow-color: #fff
) {
    & {
        overflow-@{direction}: auto;
        scrollbar-width: thin;
    }

    &:hover {
        & {
            scrollbar-color: @color fade(@color, 20%);
        }

        &::-webkit-scrollbar {
            background: @second-color;
        }

        &::-webkit-scrollbar-thumb {
            background: @color;
            border-radius: @width;
            cursor: pointer;
        }

        &::-webkit-scrollbar-track {
            box-shadow: inset 0 0 5px @shadow-color;
        }
    }

    & when not (@direction = x) {
        &::-webkit-scrollbar {
            height: 12px;
            width: @width;
        }

        &::-webkit-scrollbar-thumb {
            height: @width;
            width: @width;
        }

        ::-webkit-scrollbar-track {
            height: @width;
            width: @width;
        }
    }
}
