//
//  Shape Chooser Component
//  ______________________________________________

//
//  Common
//  ----------------------------------------------

& when (@media-common = true) {
    .amlabel-shapes-container {
        & {
            align-items: center;
            display: flex;
            flex-wrap: wrap;
            justify-content: start;
        }

        > .amlabel-shape {
            & {
                align-items: center;
                border: 1px solid #eee;
                display: flex;
                flex-wrap: nowrap;
                height: 55px;
                justify-content: center;
                margin: 0 10px 10px 0;
                padding: 0 10px;
                vertical-align: top;
                width: 115px;
            }

            &:hover {
                border-color: #c4c4c4;
            }

            &.-am-checked {
                border-color: #000;
            }
        }

        .amlabel-image {
            height: 40px;
        }
    }
}
