//
//  Visual Chooser Component
//  ______________________________________________

//
//  Common
//  ----------------------------------------------

& when (@media-common = true) {
    .amlabel-position-chooser.amlabel-visual-chooser {
        & {
            border-spacing: 6px;
        }

        td {
            & {
                padding: 5px;
            }

            &[data-amlabel-position-selected='1'] {
                background-color: #007bdb;
                border-color: #007bdb;
                color: #fff;
            }

            &:hover:not([data-amlabel-position-enabled='0'], [data-amlabel-position-selected='1']) {
                background-color: #e8f5ff;
                border-color: #000;
                color: #000;
            }
        }
    }
}
