.mstSocialLogin__accountList {
    &:extend(.abs-clearfix all);

    .mstSocialLogin__accountList-column {
        float: left;
        width: 50%;

        h3 {
            margin-top: 0;
            margin-bottom: 2rem;
            font-weight: 500;
        }
    }

    .accountList__list {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;

        > a {
            &:before {
                display: none;
            }
        }

        .accountList__provider {
            border-radius: 10rem;
            overflow: hidden;
            margin-bottom: 1rem;
            display: flex;
            flex-direction: row;
            align-items: center;
            padding: .5rem 2rem .5rem .5rem;
            max-width: 30rem;
            min-width: 22rem;

            .mstSocialLogin__button {
                padding-left: 1.5rem;

                &:before {
                    display: none;
                }
            }

            .provider-status {
                width: 3rem;
                height: 3rem;
                margin-right: 1rem;

                &._connected {
                    background-image: url("data:image/svg+xml,%0A%3Csvg width='24px' height='24px' viewBox='0 0 24 24' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3C!-- Generator: Sketch 47.1 (45422) - http://www.bohemiancoding.com/sketch --%3E%3Cdesc%3ECreated with Sketch.%3C/desc%3E%3Cdefs%3E%3C/defs%3E%3Cg id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg id='iconfinder_ok_2639876' fill-rule='nonzero' fill='%237ED321'%3E%3Cpath d='M12,0 C5.373,0 0,5.373 0,12 C0,18.627 5.373,24 12,24 C18.627,24 24,18.627 24,12 C24,5.373 18.627,0 12,0 Z M18.707,9.707 L11.147,17.267 C10.959,17.455 10.705,17.56 10.44,17.56 C10.175,17.56 9.92,17.455 9.733,17.267 L6.28,13.814 C5.889,13.423 5.889,12.791 6.28,12.4 C6.671,12.009 7.303,12.009 7.694,12.4 L10.44,15.146 L17.293,8.293 C17.684,7.902 18.316,7.902 18.707,8.293 C19.098,8.684 19.098,9.316 18.707,9.707 Z' id='Shape'%3E%3C/path%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
                    background-repeat: no-repeat;
                    background-position: center;
                }
            }

            .provider-actions {
                margin-left: auto;
            }
        }
    }
}
