//
//  One Step Checkout Tooltips
//  ____________________________________________

//
//  Variables
//  _____________________________________________

@tooltip__border-color: #999;
@tooltip__background-color: #f4f4f4;
@tooltip-arrow__size: 16px;
//
//  Common
//  ----------------------------------------------

& when (@media-common = true) {
    .amcheckout-default-tooltip.tooltipster-base.tooltipster-sidetip {
        & {
            height: auto !important; // override js styles
        }

        .tooltipster-box {
            border: 1px solid @tooltip__border-color;
            border-radius: 0;
            color: inherit !important; // override js styles
        }

        .tooltipster-content {
            padding: 12px;
            background: @tooltip__background-color;
        }

        .tooltipster-arrow {
            width: 12px;
        }

        &.tooltipster-right .tooltipster-arrow {
            left: -3px;
        }

        &.tooltipster-left .tooltipster-arrow {
            right: -2px;
            width: 14px;
        }

        .tooltipster-arrow-background {
            left: 3px;
            border-right-color: @tooltip__background-color;
            border-left-color: @tooltip__background-color;
        }

        .tooltipster-arrow-border {
            top: 2px;
            width: @tooltip-arrow__size;
            height: @tooltip-arrow__size;
            background-color: @tooltip__border-color !important; // override js styles
        }

        &.tooltipster-right .tooltipster-arrow-border {
            left: 15px;
        }

        &.tooltipster-left .tooltipster-arrow-border {
            left: -5px;
            z-index: -1;
        }
    }
}
