//----
// @category  : Ubertheme
// @package   : Ubertheme_UbMegaMenu
// @author    : Ubertheme.com
// @copyright : Copyright 2009-2020 Ubertheme
//---------------------------

//
// Effects
//  ---------------------------------------------

// --------------------
// Venitian
// --------------------

.venitian {
    &.ub-mega-menu.horizontal-root {
        li.has-child.tab-head .child-content,
        li.mega .child-content {
            left: 0;
            max-height: 0;
            overflow: inherit;
            -moz-transform: scale(1, 0);
            -moz-transform-origin: 50% 0;
            -moz-transition: opacity .4s, -moz-transform .6s, max-height .6s;
            -ms-transform: scale(1, 0);
            -ms-transform-origin: 50% 0;
            -ms-transition: opacity .4s, -ms-transform .6s, max-height .6s;
            opacity: 0;
            -o-transform: scale(1, 0);
            -o-transform-origin: 50% 0;
            -o-transition: opacity .4s, -o-transform .6s, max-height .6s;
            transform: scale(1, 0);
            transform-origin: 50% 0;
            transition: opacity .4s, transform .6s, max-height .6s;
            -webkit-transform: scale(1, 0);
            -webkit-transform-origin: 50% 0;
            -webkit-transition: opacity .4s, -webkit-transform .6s, max-height .6s;
        }

        li.has-child.tab-head.active .child-content,
        li.has-child.mega-hover>.child-content {
            max-height: 1000px;
            overflow: hidden;
            -moz-transform: none;
            -ms-transform: none;
            opacity: 1;
            -o-transform: none;
            transform: none;
            -webkit-transform: none;
        }
    }
}


// --------------------
// Pop
// --------------------

.pop {
    &.ub-mega-menu.horizontal-root {

        li.has-child.tab-head .child-content,
        li.mega .child-content {
            left: 0;
            max-height: 0;
            -moz-transform: scale(.2);
            -moz-transition: opacity .4s, -moz-transform .6s, max-height .6s;
            -moz-transition-timing-function: cubic-bezier(0, .35, .5, 1.5);
            -ms-transform: translateX(-80%);
            -ms-transition: opacity .4s, -ms-transform .6s, max-height .6s;
            -ms-transition-timing-function: cubic-bezier(0, .35, .5, 1.5);
            opacity: 0;
            -o-transform: translateX(-80%);
            -o-transition: opacity .4s, -o-transform .6s, max-height .6s;
            -o-transition-timing-function: cubic-bezier(0, .35, .5, 1.5);
            transform: translateX(-80%);
            transition: opacity .4s, transform .6s, max-height .6s;
            transition-timing-function: cubic-bezier(0, .35, .5, 1.5);
            -webkit-transform: scale(.2);
            -webkit-transition: opacity .4s, -webkit-transform .6s, max-height .6s;
            -webkit-transition-timing-function: cubic-bezier(0, .35, .5, 1.5);
        }

        li.has-child.tab-head.active .child-content,
        li.has-child.mega-hover>.child-content {
            max-height: 1000px;
            -moz-transform: none;
            -ms-transform: none;
            opacity: 1;
            -o-transform: none;
            transform: none;
            -webkit-transform: none;
        }
    }
}

// --------------------
// Linear
// --------------------

.linear {
    &.ub-mega-menu.horizontal-root {

        li.has-child.tab-head .child-content,
        li.mega .child-content {
            left: 0;
            max-height: 0;
            -moz-transform: translateX(-40%);
            -moz-transition: opacity .4s, -moz-transform .6s, max-height .6s;
            -moz-transition-timing-function: cubic-bezier(0, .35, .5, 1.7);
            -ms-transform: translateX(-40%);
            -ms-transition: opacity .4s, -ms-transform .6s, max-height .6s;
            -ms-transition-timing-function: cubic-bezier(0, .35, .5, 1.7);
            opacity: 0;
            -o-transform: translateX(-40%);
            -o-transition: opacity .4s, -o-transform .6s, max-height .6s;
            -o-transition-timing-function: cubic-bezier(0, .35, .5, 1.7);
            transform: translateX(-40%);
            transition: opacity .4s, transform .6s, max-height .6s;
            transition-timing-function: cubic-bezier(0, .35, .5, 1.7);
            -webkit-transform: translateX(-40%);
            -webkit-transition: opacity .4s, -webkit-transform .6s, max-height .6s;
            -webkit-transition-timing-function: cubic-bezier(0, .35, .5, 1.7);
        }

        li.has-child.tab-head.active .child-content,
        li.has-child.mega-hover>.child-content {
            max-height: 1000px;
            -moz-transform: none;
            -ms-transform: none;
            opacity: 1;
            -o-transform: none;
            transform: none;
            -webkit-transform: none;
        }
    }
}

.fadeinup {
    &.ub-mega-menu.horizontal-root {
        > li.mega { 
            > .child-content {
                -webkit-animation-duration: 1s;
                animation-duration: 1s;
                -webkit-animation-duration: var(--animate-duration);
                animation-duration: var(--animate-duration);
                -webkit-animation-fill-mode: both;
                animation-fill-mode: both;
            }
            &.mega-hover {
                > .child-content {
                    -webkit-animation-name: MegaFadeInUp;
                    animation-name: MegaFadeInUp;
                }
            }
        }
    }
}

.fadeindown {
    &.ub-mega-menu.horizontal-root {
        > li.mega { 
            > .child-content {
                -webkit-animation-duration: .8s;
                animation-duration: .8s;
                -webkit-animation-duration: var(--animate-duration);
                animation-duration: var(--animate-duration);
                -webkit-animation-fill-mode: both;
                animation-fill-mode: both;
            }
            &.mega-hover {
                > .child-content {
                    -webkit-animation-name: megaFadeInDown;
                    animation-name: megaFadeInDown;
                }
            }
        }
    }
}

.slideinup {
    &.ub-mega-menu.horizontal-root {
        > li.mega { 
            > .child-content {
                -webkit-animation-duration: 1s;
                animation-duration: 1s;
                -webkit-animation-duration: var(--animate-duration);
                animation-duration: var(--animate-duration);
                -webkit-animation-fill-mode: both;
                animation-fill-mode: both;
            }
            &.mega-hover {
                > .child-content {
                    -webkit-animation-name: megaSlideInUp;
                    animation-name: megaSlideInUp;
                }
            }
        }
    }
}
    
@keyframes MegaFadeInUp {
    from {
      opacity: 0;
      -webkit-transform: translate3d(0, 10%, 0);
      transform: translate3d(0, 10%, 0);
    }
  
    to {
      opacity: 1;
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
    }
}

@keyframes megaFadeInDown {
    from {
      opacity: 0;
      -webkit-transform: translate3d(0, -10%, 0);
      transform: translate3d(0, -10%, 0);
    }
  
    to {
      opacity: 1;
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
    }
}

@keyframes megaSlideInUp {
    from {
      -webkit-transform: translate3d(0%, 10%, 0);
      transform: translate3d(0%, 10%, 0);
      visibility: visible;
    }
  
    to {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
    }
}
