//
//  Swatch tooltip
//  __________________________________________________

//
//  Common
//  --------------------------------------------------

& when (@media-common = true) {
    .ui-helper-hidden-accessible {
        .lib-visually-hidden();
    }
}
