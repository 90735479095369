//----
// @category  : Ubertheme
// @package   : Ubertheme_UbMegaMenu
// @author    : Ubertheme.com
// @copyright : Copyright 2009-2021 Ubertheme
//---------------------------

//
//  Variables
//  _____________________________________________
@navigation__background: @secondary__color__light;

//
//  Common
//  _____________________________________________
& when (@media-common =true) {
    .nav-off-canvas {

        // Toggle Nav

        // .logo {
        //     margin: 0 auto 25px 40px;
        // }

        // Nav Sections

        .page-header {
            
        }

        .nav-sections {
            background-color: @color-white;
            -webkit-overflow-scrolling: touch;
            height: 100%;
            margin-bottom: 0;
            position: fixed;
            top: 0;
            .lib-css(transition, left ease-in-out 250ms);
            //left: calc(~'-1 * (100% - @{active-nav-indent})');
            //width: calc(~'100% - @{active-nav-indent}');
            left: -320px;
            width: 320px;
            z-index: 99;
            .switcher {
                border-top: 1px solid @color-gray82;
                .lib-font-size(@font-size__base * 1.15);
                font-weight: @font-weight__bold;
                margin: 0;
                padding: .8rem 3.5rem .8rem 2rem;
        
                .label {
                    display: block;
                    margin-bottom: @indent__xs;
                }
        
                &:last-child {
                    border-bottom: 1px solid @color-gray82;
                }
            }
        
            .switcher-trigger {
                strong {
                    &:extend(.ub-icon-down all);
                    position: relative;
        
                    &:after {
                        position: absolute;
                        right: -40px;
                        top: -16px;
                    }
                }
        
                &.active strong {
                    .lib-icon-font-symbol(
                        @_icon-font-content: @icon-up,
                        @_icon-font-position: after
                    );
                }
            }
        
            .switcher-dropdown {
                .lib-list-reset-styles();
                display: none;
                padding: @indent__s 0;
            }
        
            .switcher-options {
                &.active {
                    .switcher-dropdown {
                        display: block;
                    }
                }
            }
        
            .header.links {
                .lib-list-reset-styles();
                border-bottom: 1px solid @color-gray82;
        
                li {
                    .lib-font-size(@font-size__base * 1.15);
                    margin: 0;
        
                    &.greet.welcome {
                        border-top: 1px solid @color-gray82;
                        font-weight: @font-weight__bold;
                        padding: .8rem @submenu__padding-left;
                    }
        
                    > a {
                        border-top: 1px solid @color-gray82;
                    }
                }
        
                a,
                a:hover {
                    .lib-css(color, @navigation-level0-item__color);
                    .lib-css(text-decoration, @navigation-level0-item__text-decoration);
                    display: block;
                    font-weight: @font-weight__bold;
                    padding: .8rem @submenu__padding-left;
                }
        
                .header.links {
                    border: 0;
                }
            }
        
            .nav-sections-items {
                .lib-clearfix();
                position: relative;
                z-index: 1;
                max-width: inherit;
            }
        
            .nav-sections-item-title {
                background: darken(@navigation__background, 5%);
                border: solid darken(@navigation__background, 10%);
                border-width: 0 0 1px 1px;
                .ub-box-sizing(border-box);
                float: left;
                height: 51px;
                padding-top: 15px;
                text-align: center;
                width: 33.33%;
                display: block;
        
                &.active {
                    background: transparent;
                    border-bottom: 0;
                }
        
                .nav-sections-item-switch {
                    &:hover {
                        text-decoration: none;
                    }
                }
            }
        
            .nav-sections-item-content {
                .lib-clearfix();
                .ub-box-sizing(border-box);
                float: right;
                margin-left: -100%;
                margin-top: 60px;
                width: 100%;
                padding: 0 20px;
        
                &.active {
                    display: block;
                }
                > * {
                    display: block;
                }
            }
        
        }
    }

    .nav-before-open {
        height: 100%;
        // overflow-x: hidden;
        overflow-x: inherit !important;
        width: 100%;
        .page-wrapper {
            &.nav-off-canvas {
                .lib-css(transition, left ease-in-out 250ms);
                height: 100%;
                left: 0;
                // overflow: inherit;
                overflow: hidden;
                position: relative;
            }
        }

        body {
            overflow: hidden;
            height: auto !important;
            position: relative;
            width: 100%;
        }
    }

    // Nav open
    // --------------------

    .nav-open {
        .nav-off-canvas {
            &.page-wrapper {
                // left: calc(~'100% - @{active-nav-indent}');
                left: 0;
            }

            .nav-sections {
                @_shadow: 0 0 5px 0 rgba(50, 50, 50, .75);
                .lib-css(box-shadow, none);
                left: 0;
                z-index: 999;
            }

            .nav-toggle {
                top: 8px;
                position: fixed;
                left: 320px;
                z-index: 1;
                transition: all linear .4s;
                &:before {
                    .lib-css(transition, background ease-in-out 250ms);
                    background: #eb6703;
                    color: @color-white;
                    content: '\e616';
                    .lib-font-size(@font-size__base * 1.29);
                    height: 51px;
                    line-height: 51px;
                    top: 43px;
                    position: absolute;
                    right: -39px;
                    text-align: center;
                    width: 54px;
                    z-index: 11;
                }
                &:after {
                    background: rgba(0, 0, 0, 0.9);
                    content: '';
                    display: block;
                    height: 100%;
                    position: fixed;
                    right: 0;
                    top: 0;
                    width: 100%;
                    z-index: 2;
                }
            }
            .header.content {
                .block-search {
                    z-index: 0;
                }
                .logo {
                    z-index: 0;
                }
            }
        }
    }
}


//
//  Mobile
//  _____________________________________________

.media-width(@extremum, @break) when (@extremum = 'max') and (@break = @screen__m) {
    .ub-nav-sticky {
        &.sticky-fired {
            .header.content {
                .block-search {
                    display: block;
                    margin-top: 5px;
                    .control {
                        border-top: 0;
                    }
                }
            }
        }
    }
}
//
//  Tablet Landscape & Desktop
//  _____________________________________________

.media-width(@extremum, @break) when (@extremum = 'min') and (@break = @screen__m) {
    // .nav-toggle { 
    //     display: block;
    //     top: 35px;
    // }
    
    .ub-nav-sticky {
        &.nav-off-canvas {
            &.sticky-fired {
                .header.content {
                    .block-search {
                        display: block;
                        padding: 2px 0;
                    }
                }
            }
        }
    }
}
.media-width(@extremum, @break) when (@extremum = 'min') and (@break = @screen__l) {
    // .nav-toggle { 
    //     display: none;
    // }
    .nav-off-canvas {
        .nav-sections {
            overflow: initial;
            &.scroll-enabled {
                overflow: auto;
            }
        }
    }
}